import * as React from "react";
import { Container, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { Link } from "gatsby";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';


const EdgeqPressKit1 = () => (
  <Layout>
    <Seo title="EdgeQ | Edgeq Press Kit" />


    <section className="News-detail">
        <Container>

                <Row>

                  <div className="detail-head">
                   <Link to="/media/#August" className="back-btn"><FiArrowLeft/> Company Announcements</Link>

                  <h2>EdgeQ Adds Former Qualcomm CEO Paul Jacobs & CTO Matt Grob as Advisors to Disrupt the Current Closed RAN Ecosystem with RISC-V based Highly Programmable 5G and AI Platform</h2>
                  </div>

                  <div className="detail-para">
                    
              <p className="text-left"><i>Joined by Industry Luminaries and Partnered with Facebook, EdgeQ Delivers OpenRAN with Integrated 5G and AI to Set New Performance and Power Standards</i></p>

              <div className="ajit-social">
                <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>
            <p><b>SANTA CLARA, CA</b> – January 26, 2021 – <Link to="/">EdgeQ Inc</Link>, a 5G systems-on-a-chip company, announced today that Dr. Paul Jacobs and Matt Grob have joined the advisory board. As a former Chief Executive Officer and executive chairman of Qualcomm, Dr. Jacobs is a highly respected leader in the cellular space for many decades, having developed and commercialized fundamental mobile technology breakthroughs that fueled the wireless internet and smartphone revolutions from 2G to 5G. Grob previously spent over two decades at Qualcomm and served as Chief Technology Officer and executive vice president of technology. Their involvement with EdgeQ will further strengthen key leadership positions as the company nears delivery of the industry’s first fully open and programmable 5G platform this year.</p>

            <p>The wireless infrastructure industry is undergoing a sea change where the cellular stack is becoming disaggregated and virtualized.  Traditional solutions are unable to service the new wave of 5G systems and applications. With EdgeQ, customers can now leverage a RISC-V open ecosystem for cellular deployment, provide targeted differentiation and customization, all while being capital efficient.</p>

            <p>“The advent of 5G comes with changes in the architecture of wireless networks and an exciting expansion in the types of applications and services,” said Dr. Paul Jacobs, former CEO of Qualcomm and CEO of XCOM. “This inflection point creates the opportunity to build and leverage an open ecosystem based on new platforms and a greater diversity of participants. EdgeQ's solution, based on the open design of RISC-V processors, enables innovation deeper in the wireless technology stack. This will allow both general performance improvements as well as the design of wireless systems that are tailored to specific use cases. I look forward to working with new entrants and pioneers, such as EdgeQ, to capitalize on wireless innovations and market forces that will bring to life the expected benefits of 5G and beyond."</p>

            <p>“5G opens an uncharted world of novel applications that require unprecedented wireless performance achieved with techniques such as joint processing, massive MIMO, and adaptive beamforming at the base station,” said Matt Grob, former CTO of Qualcomm. “EdgeQ’s converged 5G and AI capabilities make for a unique solution to accomplish these tasks at lower hardware cost and at lower power. EdgeQ also fosters a new paradigm within the wireless infrastructure industry where customers can now program all layers of the PHY. Only a few vendors today can provide the customizable PHY capabilities that EdgeQ offers.”</p>

            <p>“We are fortunate to have highly regarded advisors like Paul Jacobs and Matt Grob, and strategic partners like Facebook and RISC-V, to drive a new operating model for an industry that has traditionally been proprietary, expensive and complex,” said Vinay Ravuri, CEO and Founder of EdgeQ. “Today’s infrastructure market is a dichotomy of closed systems or do-it-yourself solutions by customers who lack the proper wherewithal. Our value at EdgeQ is to provide a readied solution, deployable from the onset, and easily modifiable by customers via open-source RISC-V tools.”</p>

            <p>Architected for the 5G era, EdgeQ’s clean-slate approach starts with an open RISC-V design. But unlike conventional implementation of RISC-V, EdgeQ uniquely extends RISC-V instruction sets to converge both 5G connectivity and machine learning inference in a fully coherent and performant manner.  In doing so, EdgeQ’s open and programmable design can natively scale to meet the performance demand of today’s infrastructure, at a significantly reduced power and cost point. </p>

            <p>“At Facebook, we are guided by the vision to connect everyone. Democratizing internet access requires a new networking infrastructure that embraces open hardware, software, and tools,” said Facebook. “By offering a programmable OpenRAN solution unlike closed legacy architectures, EdgeQ empowers equipment and telco providers with disruptive access to 5G, freedom to customize at the physical layers and above, and deliver state-of-the-art performance at disruptive power. All this greatly motivates Facebook to develop an open Radio System reference design based on the EdgeQ solution.”</p>

            <p>“The way in which EdgeQ is bringing RISC-V architecture into the cellular space is symbolic of the creative possibilities where an open standard instruction architecture unlocks new markets and application spaces,” said Calista Redmond, CEO of RISC-V International. “EdgeQ and wireless customers are beneficiaries of our evolving ecosystem which is making available and accessible a rich set of RISC-V tools for the communications market. We are delighted that EdgeQ joined RISC-V International in the 5G space,”.</p>

            <p>Learn more about how EdgeQ is pioneering the 5G infrastructure market and explore employment opportunities, visit <Link to="/career">https://edgeq.io/career</Link></p>
              <b>About EdgeQ</b>
              <br />
              <br />
              <p>EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering a converged connectivity and AI that is fully software-customizable and programmable. The company is backed by leading investors, including Threshold Partners, Fusion Fund and AME Cloud Ventures, among others. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link></p>

              <b>Contacts</b>
              <br />
              <br />
              <a target="_blank" href="https://goo.gl/maps/qwqsKwcTcEVokx1e6">Jordan Tewell, 10Fold Communications</a>
              <br />
              <a href="mailto:edgeq@10fold.com">edgeq@10fold.com</a>
              <br />
              <a href="tel:+4156666066">(415) 666-6066</a>
</div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default EdgeqPressKit1;
